import React from "react"
import {
	Snackbar as MUISnackbar,
	SnackbarContent,
	IconButton
} from "@material-ui/core"
import CloseIcon from "@material-ui/icons/Close"

export default function Snackbar({ closeFn, open, m }) {
	return (
		<MUISnackbar
			anchorOrigin={{
				vertical: "bottom",
				horizontal: "center"
			}}
			open={open}
			autoHideDuration={5000}
			style={{ background: "#2f33ee" }}
			onClose={closeFn}>
			<SnackbarContent
				message={<span id='digi-snackbar'>{m}</span>}
				action={[
					<IconButton
						key='close'
						aria-label='close'
						color='inherit'
						onClick={closeFn}>
						<CloseIcon />
					</IconButton>
				]}
			/>
		</MUISnackbar>
	)
}
