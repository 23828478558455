import React, { useEffect, useRef, useState } from "react"
import ReactPlayer from "react-player"
import { Link } from "react-router-dom"
import Divider from "@material-ui/core/Divider"
import ShareTwoToneIcon from "@material-ui/icons/ShareTwoTone"
import { toast } from "react-toastify"
import Dialog from "@material-ui/core/Dialog"
import Paper from "@material-ui/core/Paper"
import ButtonBase from "@material-ui/core/ButtonBase"
import CloseIcon from "@material-ui/icons/Close"
import { makeStyles } from "@material-ui/core/styles"
import * as firebase from "firebase/app"
import Toolbar from "components/nav/toolbar"
import "./Player.css"

const useStyles = makeStyles(theme => ({
  background: {
    paddingTop: theme.spacing(4),
    background: theme.baseColors.playerBackground,
    position: "absolute",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    "& video": {
      maxHeight: "65vh"
    }
  },
  metaVideo: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "flex-end",
    fontSize: "1.4rem",
    color: theme.baseColors.offWhite,
    padding: theme.spacing(1),
    "& .right-menu": {
      display: "flex",
      justifyContent: "space-evenly",
      alignItems: "center",
      fontSize: "1rem",
      "& > div": {
        marginLeft: theme.spacing(1)
      }
    },
    "& .share": {
      cursor: "pointer"
    }
  },
  dialog: {
    background: "rgba(0,0,0,0.4)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    "& .Paper": {
      display: "flex",
      padding: theme.spacing(1),
      flexFlow: "column",
      background: "#282828",
      width: "50vw",
      height: "150px",
      maxWidth: "575px",
      [theme.breakpoints.only("sm")]: {
        width: "80vw"
      },
      [theme.breakpoints.only("xs")]: {
        width: "100vw"
      }
    }
  },
  shareModal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
    "& input": {
      marginRight: theme.spacing(1),
      width: "65%",
      background: "#252525",
      color: "#bdbcbc",
      border: "none",
      padding: theme.spacing(0.5)
    }
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    color: "white"
  }
}))

const PlayerNew = ({ url, match: { params }, history }) => {
  const timer = useRef(0)
  const classes = useStyles()
  const [durationOfVideo, setDurationOfVideo] = useState(0)
  const [video, setVideo] = useState(null)
  const [shareModal, setShareModal] = useState(false)

  const handleShareModal = () => setShareModal(!shareModal)
  const handleCopy = async () => {
    try {
      await navigator.clipboard.writeText(window.location.href)
      toast.info("Copied!", { autoClose: 1500 })
    } catch (e) {
      console.log("error", e)
    }
  }

  useEffect(() => {
    const watchCountTimer = setInterval(() => {
      timer.current++
    }, 1000)
    return () => clearInterval(watchCountTimer)
  }, [video])

  useEffect(() => {
    const unMountPlayer = () => {
      if (video != null) {
        const percentWatched =
          durationOfVideo > 1 ? (timer.current / durationOfVideo).toFixed(2) * 100 : null
        if (percentWatched > 30) {
          const videoDoc = firebase
            .firestore()
            .collection("Video")
            .doc(params.id)
          videoDoc.update({
            watchCount: firebase.firestore.FieldValue.increment(1)
          })
        } else {
        }
      }
    }
    return unMountPlayer
  }, [durationOfVideo, params.id, video])

  useEffect(() => {
    if (params.id) {
      firebase
        .firestore()
        .collection("Video")
        .doc(params.id)
        .get()
        .then(res => {
          if (res.data() != null) {
            setVideo(res.data())
          } else {
            history.push("/dashboard")
          }
        })
        .catch(e => toast.error("No Video Found"))
    }
  }, [params.id, history])

  return (
    <div className={classes.background}>
      <Toolbar logoOnly />
      {video && (
        <div
          style={{
            position: "relative"
          }}>
          <ReactPlayer
            url={video.streamUrl}
            onProgress={e => {}}
            onDuration={duration => {
              setDurationOfVideo(duration)
            }}
            playing
            controls
            width='100%'
            height='100%'
          />
          <div className={classes.metaVideo}>
            <div className='font-menloRegular title'>{video && video.title}</div>
            <div className='right-menu'>
              <div className='share' onClick={handleShareModal}>
                SHARE
                <ShareTwoToneIcon />
              </div>
              <div className='font-menloRegular views'>
                {video && video.watchCount ? (
                  video.watchCount > 1 ? (
                    <div>{`${video.watchCount} views`}</div>
                  ) : (
                    <div>{`${video.watchCount} view`}</div>
                  )
                ) : null}
              </div>
            </div>
          </div>
        </div>
      )}
      <Dialog PaperProps={{ className: classes.dialog }} fullScreen open={shareModal}>
        <Paper className='Paper'>
          <div className={classes.header}>
            <div>Share</div>
            <ButtonBase className={classes.closeIcon} onClick={handleShareModal}>
              <CloseIcon />
            </ButtonBase>
          </div>
          <Divider style={{ background: "#3a3a3a", marginTop: "2px" }} />
          <div className={classes.shareModal}>
            <input value={window.location.href} readOnly />
            <Link to='#' onClick={handleCopy}>
              COPY
            </Link>
          </div>
        </Paper>
      </Dialog>
    </div>
  )
}

export default PlayerNew
