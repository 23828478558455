const validate = values => {
  const emailRegex = /\S+@\S+\.\S+/i
  let errors = {}
  if (!values.email) {
    errors.email = "Please enter an email address."
  }
  if (values.email && !values.email.match(emailRegex)) {
    errors.email = "Please enter a valid email address."
  }
  return errors
}
export default validate
