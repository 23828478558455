import React from "react"
import Paper from "@material-ui/core/Paper"
import MUIModal from "@material-ui/core/Modal"
import Button from "@material-ui/core/Button"
import { makeStyles } from "@material-ui/core"

const useStyles = makeStyles(theme => ({
	modal: {
		height: "50vh",
		width: "50%",
		maxWidth: "750px",
		minWidth: "400px",
		display: "flex",
		flexFlow: "column",
		alignItems: "center",
		justifyContent: "center",
		padding: "2rem",
		textAlign: "center"
	},
	container: {
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		height: "100%",
		width: "100%"
	},
	button: {
		background: "#2f33ee",
		color: theme.baseColors.white
	}
}))

const Modal = ({ modal, setModal, handleModalClose }) => {
	const classes = useStyles()
	return (
		<MUIModal open={modal} onClose={() => setModal(false)}>
			<div className={classes.container}>
				<Paper className={classes.modal}>
					<h3 style={{ marginBottom: "3rem" }}>
						Video asset will be available once encoding process is complete.
					</h3>
					<Button className={classes.button} onClick={handleModalClose}>
						Ok
					</Button>
				</Paper>
			</div>
		</MUIModal>
	)
}

export default Modal
