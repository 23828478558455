import React from "react"
import Button from "components/button/button"

export default function Welcome() {
	return (
		<>
			<div className='Sign_up_complete_'>Sign up complete!</div>

			<div className='Ultra_HD_Available'>Ultra HD Available</div>
			<div className='_70_movies_and_shows'>70 movies and shows</div>

			<div className='Watch_on_any_Device'>Watch on any Device</div>
			<div className='Download_Available'>Download Available</div>
			<Button styles='verify' text='Start Watching' />
		</>
	)
}
