import React, { useState, useEffect } from "react"
import { Form } from "react-final-form"
import TextField from "components/finalForm/TextField"
import Paper from "@material-ui/core/Paper"
import Button from "components/button/button"
import { makeStyles } from "@material-ui/core/styles"
import { toast } from "react-toastify"
import Toolbar from "components/nav/toolbar"
import validate from "./validate"
import * as firebase from "firebase/app"

const useStyles = makeStyles(theme => ({
  form: {
    height: "100vh",
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },
  fields: {
    display: "flex",
    flexFlow: "column",
    justifyContent: "center",
    alignItems: "center",
    [theme.breakpoints.up("sm")]: {
      flexFlow: "row"
    }
  },
  desc: {
    textAlign: "center"
  },
  email: {
    margin: theme.spacing(1),
    minWidth: "285px",
    [theme.breakpoints.up("sm")]: {
      width: "350px"
    }
  },
  thankyou: {
    textAlign: "center",
    height: "100vh",
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  thankEmail: {
    minWidth: "50%",
    maxWdith: "500px",
    padding: theme.spacing(3),
    [theme.breakpoints.only("xs")]: {
      width: "100%"
    }
  }
}))

const ForgotPassword = props => {
  const classes = useStyles()
  const [submitted, setSubmitted] = useState(false)
  let delay
  const handleSubmit = values =>
    firebase
      .auth()
      .sendPasswordResetEmail(values.email)
      .then(() => {
        setSubmitted(true)
        delay = setTimeout(() => {
          props.history.push("/login")
        }, 8000)
      })
      .catch(res => {
        if (res.code === "auth/user-not-found") {
          toast.error("No user exists with that email address.")
        }
      })

  useEffect(() => clearTimeout(delay), [delay])

  return (
    <div>
      {!submitted ? (
        <React.Fragment>
          <Toolbar logoOnly />
          <Form onSubmit={handleSubmit} validate={validate}>
            {({ handleSubmit, errors }) => {
              const isErrors = Object.keys(errors).length > 0
              return (
                <form
                  onSubmit={e => {
                    e.preventDefault()
                    if (isErrors) {
                      Object.values(errors).forEach(error => toast.info(error))
                    } else {
                      handleSubmit()
                    }
                  }}
                  className={classes.form}>
                  <div>
                    <div className={classes.desc}>Please enter your email address:</div>
                    <div className={classes.fields}>
                      <TextField name='email' className={classes.email} />
                      <Button type='submit'>Submit</Button>
                    </div>
                  </div>
                </form>
              )
            }}
          </Form>
        </React.Fragment>
      ) : (
        <div className={classes.thankyou}>
          <Paper className={classes.thankEmail}>Thank you. Please check your Email.</Paper>
        </div>
      )}
    </div>
  )
}

export default ForgotPassword
