// import React, { useState, useContext } from "react"
import React, { useState } from "react"
import { Form } from "react-final-form"
import { withRouter } from "react-router-dom"
import { makeStyles } from "@material-ui/core/styles"
import validate from "components/finalForm/util"
import { toast, ToastContainer } from "react-toastify"
import TextField from "components/finalForm/TextField"
import Grid from "@material-ui/core/Grid"
import Button from "components/button/button"
import Typography from "@material-ui/core/Typography"
import * as firebase from "firebase/app"

const useStyles = makeStyles(theme => ({
  agree: {
    display: "flex",
    "& button": {
      marginRight: theme.spacing(1)
    }
  },
  inputs: {
    padding: theme.spacing(1),
    minWidth: "258px",
    [theme.breakpoints.down("md")]: {
      padding: 0
    }
  },
  inputFields: {
    padding: theme.spacing(2)
  },
  passwords: {
    display: "flex",
    marginBottom: theme.spacing(0.5)
  },
  submitContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  }
}))

const SignupForm = props => {
  const classes = useStyles()
  const [emailInUse, setEmailInUse] = useState(false)
  const [complete, setComplete] = useState(false)

  const submit = values => {
    setEmailInUse(false)
    let userInDb = false
    //check to see if user exists
    firebase
      .firestore()
      .collection("users")
      .get()
      .then(querySnapshot => {
        querySnapshot.forEach(doc => {
          const email = doc.data().email
          if (email && email.toLowerCase() === values.email.toLowerCase()) {
            userInDb = true
            doc.ref.update({
              isActive: true
            })
          }
        })
      })
      .then(() => {
        if (!userInDb) {
          return firebase.auth().createUserWithEmailAndPassword(values.email, values.password)
          // return firebase.auth().sendSignInLinkToEmail(values.email, actionCodeSettings)
        } else {
          setEmailInUse(true)
          throw new Error("That email address is currently in use.")
        }
      })
      .then(() => {
        firebase
          .firestore()
          .collection("users")
          .add({
            displayName: `${values.firstName} ${values.lastName}`,
            firstName: values.firstName,
            LastName: values.lastName,
            email: values.email,
            isAdmin: false
          })
      })
      .then(() => {
        setComplete(true)
        setTimeout(() => {
          props.history.push("/dashboard")
        }, 4000)
      })
      .catch(e => toast.error(e.message))
  }

  return (
    <React.Fragment>
      {complete ? (
        <div>Welcome to DigiWatch! You will now be directed to the dashboard.</div>
      ) : (
        <Form validate={validate} onSubmit={submit}>
          {({ handleSubmit, values, errors }) => {
            const isErrors = Object.keys(errors).length > 0
            return (
              <form
                onSubmit={e => {
                  e.preventDefault()
                  if (isErrors) {
                    Object.values(errors).forEach(error => toast.info(error))
                  } else {
                    handleSubmit()
                  }
                }}>
                <div className={classes.inputs}>
                  <TextField name='firstName' label='First Name' fullWidth />
                  <TextField name='lastName' label='Last Name' fullWidth />
                  <TextField
                    name='email'
                    label='Email'
                    fullWidth
                    override={emailInUse.toString()}
                  />
                  <Grid container spacing={1} className={classes.passwords}>
                    <Grid item xs={12} md={6}>
                      <TextField
                        name='password'
                        className={classes.inline}
                        label='Password'
                        type='password'
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <TextField
                        inputProps={{ placeholder: "Confirm Password" }}
                        error={Boolean(errors.confirmPassword)}
                        className={classes.inline}
                        name='confirmPassword'
                        type='password'
                        fullWidth
                      />
                    </Grid>
                  </Grid>
                  {/* <TextField
                name='country'
                label='Country'
                value={country}
                onChange={handleMenu}
                select
                fullWidth>
                <MenuItem value='America'>America</MenuItem>
              </TextField> */}
                  <div className={classes.agree}>
                    <Button bType='submit' type='submit'>
                      Submit
                    </Button>
                    <div className={classes.submitContainer}>
                      <Typography>
                        By clicking Submit you agree to the DigiWatch{" "}
                        <a href='/terms' target='_blank'>
                          terms and conditions
                        </a>
                        .
                      </Typography>
                    </div>
                  </div>
                </div>
              </form>
            )
          }}
        </Form>
      )}
      <ToastContainer style={{ textAlign: "center" }} closeButton={false} />
    </React.Fragment>
  )
}

export default withRouter(SignupForm)
