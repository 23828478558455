import React, { useEffect } from "react"
import firebase from "firebase/app"

const ConfirmationLogin = props => {
  useEffect(() => {
    if (firebase.auth().isSignInWithEmailLink(window.location.href)) {
      var email = window.localStorage.getItem("emailForSignin")
      if (!email) {
        email = window.prompt("Please provide your email for confirmation.")
      }
      firebase
        .auth()
        .signInWithEmailLink(email, window.location.href)
        .then(res => {
          window.localStorage.removeItem("emailForSignin")
          setTimeout(() => {
            props.history.push("/dashboard")
          }, 4000)
        })
    }
  }, [props.history])
  return <div>Thank you for signing in.</div>
}

export default ConfirmationLogin
