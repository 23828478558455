/* eslint-disable no-console */
export const INCREMENT = "INCREMENT"
export const ADD_RESULT = "ADD_RESULT"
export const DELETE_RESULT = "DELETE_RESULT"
export const LOGIN = "LOGIN"
export const ACTIVE_SIDEBAR = "ACTIVE_SIDEBAR"
export const SEARCH_QUERY = "SEARCH_QUERY"
export const UPLOAD_VIDEO = "UPLOAD_VIDEO"
export const SEARCH_TOGGLE = "SEARCH_TOGGLE"
export const MINI_MODAL = "MINI_MODAL"
export const CATEGORY = "CATEGORY"
export const CATEGORIES = "CATEGORIES"

export const increment = () => {
  return {
    type: INCREMENT
  }
}

export const login = value => {}

// function that runs inside the async version
export const saveResult = value => {
  const newValue = value * 2
  return {
    type: ADD_RESULT,
    result: newValue
  }
}

// this is the async trigger to then run the function above
export const addResult = value => {
  return (dispatch, getState) => {
    setTimeout(() => {
      console.log(getState())
      dispatch(saveResult(value))
    }, 2000)
  }
}

export const deleteResult = value => {
  return {
    type: DELETE_RESULT,
    id: value
  }
}
