import React, { useEffect } from "react"
import useAuth from "hooks/useAuth"
import * as firebase from "firebase/app"

// material

// styles
import "assets/css/bootstrap.min.css"
import "assets/icons/fontawesome/css/fontawesome-all.min.css"
import "assets/css/style.css"

const Main = props => {
  const { user, isLoading } = useAuth(firebase.auth())

  useEffect(() => {
    if (user) {
      props.history.push("/dashboard")
    }
  }, [user, props.history])

  if (isLoading) return null
  return (
    <div>
      <div className='flexslider progression-studios-slider'>
        <ul className='slides'>
          <li className='progression_studios_animate_in'>
            <div
              className='progression-studios-slider-image-background'
              style={{
                backgroundImage:
                  "url(https://images.unsplash.com/photo-1557211699-ab114b727869?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1920&q=80)"
              }}>
              <div className='progression-studios-slider-display-table'>
                <div className='progression-studios-slider-vertical-align'>
                  <div className='container'>
                    <div className='progression-studios-slider-caption-width'>
                      <div className='progression-studios-slider-caption-align'>
                        <h2>Welcome to DRI-STREAM</h2>
                        <h6>
                          Offering Video on Demand and Streaming in 4k at a fraction of cost
                          compared to the competition
                        </h6>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className='progression-studios-slider-mobile-background-cover' />
            </div>
          </li>
          <li className='progression_studios_animate_right'>
            <div
              className='progression-studios-slider-image-background'
              style={{
                backgroundImage:
                  "url(https://images.unsplash.com/photo-1522327646852-4e28586a40dd?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1920&q=80)"
              }}>
              <div className='progression-studios-slider-display-table'>
                <div className='progression-studios-slider-vertical-align'>
                  <div className='container'>
                    <div className='progression-studios-slider-caption-width'>
                      <div className='progression-studios-slider-caption-align'>
                        <h2 className='light-fonts-pro'>
                          Embedded on your personal site or a custom URL portal
                        </h2>
                        <h6 className='light-fonts-pro'>
                          We have you covered to provide the best online video portal. Talk with our
                          team about how we can help you improve your users online experience.
                        </h6>
                        <a
                          className='btn btn-green-pro btn-slider-pro'
                          href='dashboard-home.html'
                          role='button'>
                          View The Video Library
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </li>
          <li className='progression_studios_animate_left'>
            <div
              className='progression-studios-slider-image-background'
              style={{
                backgroundImage:
                  "url(https://images.unsplash.com/photo-1534990702827-fbff7855edbc?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1920&q=80)"
              }}>
              <div className='progression-studios-slider-display-table'>
                <div className='progression-studios-slider-vertical-align'>
                  <div className='container'>
                    <div className='progression-studios-slider-caption-width'>
                      <div className='progression-studios-slider-caption-align'>
                        <h2 className='light-fonts-pro'>Adapted for You</h2>
                        <h6 className='light-fonts-pro'>
                          Integrations available from full production studios to mobile solutions
                          using SlingStudio.
                        </h6>
                        <a
                          className='btn btn-green-pro btn-slider-pro btn-shadow-pro'
                          href='signup-step1.html'
                          role='button'>
                          Start Your Free Trial
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className='progression-studios-slider-mobile-background-cover' />
            </div>
          </li>
        </ul>
      </div>
      <div id='content-pro'>
        <div className='container'>
          <div className='row'>
            <div className='col-md my-auto'>
              <img
                src='https://images.unsplash.com/photo-1442504028989-ab58b5f29a4a?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=705&q=80'
                className='img-fluid'
                alt='Watch in Any Devices'
              />
            </div>
            <div className='col-md my-auto'>
              <h2 className='short-border-bottom'>Stream Live or OnDemand</h2>
              <p>
                Whether you want to live stream an event or provide access to videos on demand we
                have you covered. All in video resolutions up to 4k.
              </p>
              <div style={{ height: "15px" }} />
              <p>
                <a className='btn btn-green-pro' href='index.html' role='button'>
                  Learn More
                </a>
              </p>
            </div>
          </div>
          <div className='row'>
            <div className='col-md my-auto'>
              <h2 className='short-border-bottom'>Bring Your Own Site</h2>
              <p>
                Not interested in having your users go to a different site to access videos? No
                problem, we can work with you to embed our platform into your site.
              </p>
              <div style={{ height: "15px" }} />
              <p>
                <a className='btn btn-green-pro' href='index.html' role='button'>
                  Learn More
                </a>
              </p>
            </div>
            <div className='col-md my-auto'>
              <img
                src='https://images.unsplash.com/photo-1426024120108-99cc76989c71?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=705&q=80'
                className='img-fluid'
                alt='Make Your Own Playlist'
              />
            </div>
          </div>
          <div className='row'>
            <div className='col-md my-auto'>
              <img
                src='https://images.unsplash.com/photo-1486611367184-17759508999c?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=705&q=80'
                className='img-fluid'
                alt='Watch in Ultra HD'
              />
            </div>
            <div className='col-md my-auto'>
              <h2 className='short-border-bottom'>Automated Solutions</h2>
              <p>
                We can work with you to provide hardware and software integrations to help automate
                the process of starting streams and creating new videos on the platform.
              </p>
              <div style={{ height: "15px" }} />
              <p>
                <a className='btn btn-green-pro' href='index.html' role='button'>
                  Learn More
                </a>
              </p>
            </div>
          </div>
          <div style={{ height: "35px" }} />
          <div className='clearfix' />
        </div>
      </div>
    </div>
  )
}

export default Main
