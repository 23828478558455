import React from "react"
import Typography from "@material-ui/core/Typography"
import { makeStyles } from "@material-ui/core/styles"
import MUIList from "@material-ui/core/List"
import ListItem from "@material-ui/core/ListItem"
import ListItemText from "@material-ui/core/ListItemText"

const useStyles = makeStyles(theme => ({
  TOC: {
    background: "#F0F0F0",
    marginBottom: "1rem",
    "& h5": {
      marginBottom: "0"
    }
  },
  sectionTitle: {
    margin: theme.spacing(1, 0)
  },
  ListItem: {
    padding: 0,
    "& .num": {
      marginRight: theme.spacing(1)
    }
  },
  wrapper: {
    padding: theme.spacing(2)
  }
}))

export default function Terms() {
  const classes = useStyles()
  return (
    <div className={classes.wrapper}>
      <Typography variant='h3' align='center'>
        DigiWatch Copyright Policy
      </Typography>
      <Typography variant='subtitle1' align='center'>
        November 20, 2019
      </Typography>
      <div className={classes.TOC}>
        <Title>Table of Contents</Title>
        <List
          listitems={[
            "DMCA Policy",
            "DMCA Takedown Notices",
            "DMCA Counter-Notifications",
            "Repeat Infringers",
            "DigiWatch's Copyright Agent"
          ]}
          disableGutters
        />
      </div>
      <Typography>
        This DigiWatch Copyright Policy is a part of DigiWatch's Terms of Service and sets forth the
        process by which copyright holders and their agents may remove allegedly infringing
        materials available on DigiWatch's online service.
      </Typography>
      <div>
        <Title>1. DMCA Policy</Title>
        <Typography>
          DigiWatch respects the intellectual property of others and expects its users to do the
          same. Each user must ensure that the materials they upload do not infringe any third-party
          copyright. DigiWatch will promptly remove materials in accordance with the Digital
          Millennium Copyright Act ("DMCA") when properly notified that the materials infringe a
          third party's copyright. DigiWatch will also, in appropriate circumstances, terminate the
          accounts of repeat copyright infringers. In filing any request, please ensure that your
          notice is complete and that your statements are accurate. If we request additional
          information necessary to make your notice complete, please provide that information
          promptly. If you fail to provide the required information, your request may not be
          processed further. For non-copyright complaints, please contact us..
        </Typography>
      </div>
      <div>
        <Title>2. DMCA Takedown Notices</Title>
        <Typography>
          To request the removal of materials based upon copyright infringement, you must file a
          notice containing the following:
        </Typography>
        <List
          listitems={[
            "Your name, address, telephone number, and email address (if any).",
            "A description of the copyrighted work that you claim has been infringed.",
            "A description of where on DigiWatch's service the material that you claim is infringing may be found, sufficient for DigiWatch to locate the material (e.g., the URL for the video).",
            "A statement that you have a good faith belief that the use of the copyrighted work is not authorized by the copyright owner, its agent, or the law.",
            "A statement by you UNDER PENALTY OF PERJURY that the information in your notice is accurate and that you are the copyright owner or authorized to act on the copyright owner's behalf.",
            "Your electronic or physical signature."
          ]}
          disableGutters
        />
        <Typography>You may file your notice:</Typography>
        <BulletListItem>
          By email:{" "}
          <a href='mailto:takedown@Digi-Watch.com'>
            <span style={{ marginLeft: "5px" }}>takedown@Digi-Watch.com</span>
          </a>
        </BulletListItem>
        <Typography>
          DigiWatch may disclose notices with affected users and third-party databases that collect
          information about copyright takedown notices
        </Typography>
      </div>
      <div>
        <Title>3. DMCA Counter-Notifications</Title>
        <Typography>
          If you are a DigiWatch user who wishes to challenge the removal of materials caused by a
          DMCA takedown notice, you must file a counter-notification containing the following:
        </Typography>
        <List
          listitems={[
            "Your name, address, and telephone number.",
            "A description of the material that was removed and the location on DigiWatch's service where it previously appeared (e.g., the URL of the video).",
            "A statement UNDER PENALTY OF PERJURY that you have a good faith belief that the material was removed or disabled as a result of mistake or misidentification.",
            "A statement that you consent to the jurisdiction of the Federal District Court for the judicial district in which your address is located, or if your address is outside of the United States, any judicial district in which DigiWatch may be found (the United States District Court for the Southern District of New York), and that you will accept service of process from the person who filed the original DMCA notice or an agent of that person.",
            "Your electronic or physical signature."
          ]}
          disableGutters
        />
        <Typography>You may submit this notice: </Typography>
        <BulletListItem>
          By email:{" "}
          <a href='mailto:takedown@Digi-Watch.com'>
            <span style={{ marginLeft: "5px" }}>takedown@Digi-Watch.com</span>
          </a>
        </BulletListItem>
        <Typography>
          DigiWatch will forward any complete counter-notification to the person who filed the
          original DMCA notice. The copyright owner(s) may elect to file a lawsuit against you for
          copyright infringement. If we do not receive notice that such a lawsuit has been filed
          within ten (10) business days after we provide notice of your counter-notification, we may
          restore the challenged materials. Until that time, your materials will remain removed.
        </Typography>
      </div>
      <div>
        <Title>4. Repeat Infringers</Title>
        <Typography>
          DigiWatch will terminate user accounts that receive three (3) DMCA strikes. A "DMCA
          strike" accrues each time that material is removed from a user's account due to a DMCA
          notice. We may group multiple DMCA notices received within a short period of time as a
          single DMCA strike.
        </Typography>
        <Typography>
          We may remove a DMCA strike in appropriate circumstances, such as where (1) the underlying
          material is ultimately restored due to a DMCA counter-notification; or (2) the claimant
          withdraws the underlying notice.
        </Typography>
        <Typography>
          We may terminate user accounts that receive fewer than three (3) DMCA strikes in
          appropriate circumstances, such as where the user has a history of violating or willfully
          disregarding our Terms of Service.
        </Typography>
      </div>
      <div>
        <Title>5. DigiWatch's Copyright Agent</Title>
        <Typography>You may address inquiries to DigiWatch</Typography>
        <div style={{ marginTop: "2rem" }}>
          <Typography>Driven Solutions</Typography>
          <Typography>Attn: Legal Dept. – Copyright Agent</Typography>
          <Typography>3964 Watercourse Drive</Typography>
          <Typography>#1428</Typography>
          <Typography>Fort Worth, TX 76109</Typography>
          <Typography>takedown@Digi-Watch.com</Typography>
        </div>
      </div>
    </div>
  )
}

const Title = props => {
  const classes = useStyles()
  return (
    <Typography variant='h5' className={classes.sectionTitle} {...props}>
      {props.children}
    </Typography>
  )
}
const List = props => {
  const classes = useStyles()
  return (
    <MUIList>
      {props.listitems.map((item, i) => (
        <ListItem key={item} className={classes.ListItem} {...props}>
          <ListItemText>
            <span className='num'>{i + 1}.</span>
            {item}
          </ListItemText>
        </ListItem>
      ))}
    </MUIList>
  )
}

const BulletListItem = props => {
  return (
    <MUIList style={{ display: "list-item", marginLeft: "1.3rem", listStyle: "disc" }}>
      <ListItem style={{ listStyle: "disc" }}>{props.children}</ListItem>
    </MUIList>
  )
}
