import React, { useReducer, useEffect } from "react"
import * as actions from "config/store/actions/actions"
import * as firebase from "firebase/app"

const VideoContext = React.createContext([{}, () => {}])

const VideoProvider = ({ children }) => {
  const [state, setState] = useReducer(videoReducer, {
    active: 0,
    videoUpload: false,
    searchToggle: false,
    miniModal: false,
    category: "Government",
    categories: []
  })

  useEffect(() => {
    let myCategories = []
    const fb = firebase.firestore().collection("categories")
    fb.get().then(docs => {
      docs.forEach(doc => {
        myCategories.push({ name: doc.data().name, id: doc.id })
      })
      setState({ type: actions.CATEGORIES, payload: myCategories })
    })
  }, [])

  return (
    <VideoContext.Provider value={[state, setState]}>
      {children}
    </VideoContext.Provider>
  )
}
const videoReducer = (state = {}, { type, payload }) => {
  switch (type) {
    case actions.ACTIVE_SIDEBAR:
      return { ...state, active: payload }
    case actions.SEARCH_QUERY:
      return { ...state, searchQuery: payload }
    case actions.UPLOAD_VIDEO:
      return { ...state, videoUpload: !state.videoUpload }
    case actions.SEARCH_TOGGLE:
      return { ...state, searchToggle: !state.searchToggle }
    case actions.MINI_MODAL:
      return { ...state, miniModal: !state.miniModal }
    case actions.CATEGORIES:
      return { ...state, categories: payload }
    default:
      return state
  }
}

export { VideoContext, VideoProvider }
