import React from "react"
import { Link } from "react-router-dom"
import whiteLogo from "assets/images/DigiWatch-White.png"
import blackLogo from "assets/images/DigiWatch Black.png"
import { makeStyles } from "@material-ui/core"

const useStyles = makeStyles(theme => ({
  container: {
    background: theme.baseColors.blue,
    marginRight: "2rem"
  },
  image: {
    minWidth: "120px"
  }
}))

export default function Logo(props) {
  const classes = useStyles()
  return (
    <div className={classes.container} {...props}>
      <Link to='/dashboard'>
        <img
          className={classes.image}
          src={props.black ? blackLogo : whiteLogo}
          alt='digiwatch'
          style={{ maxHeight: "25px", marginRight: "auto" }}
        />
      </Link>
    </div>
  )
}
