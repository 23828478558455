import React, { useState } from "react"

const UserContext = React.createContext([{}, () => {}])

const UserProvider = ({ children }) => {
  const [state, setState] = useState({ auth: false }) // billing is set to true for dev purposes
  // a user object will be placed in here from firebase upon authentication
  // it will have the following properties:
  // user = {
  // uid, displayName, photoURL, email, phoneNumber, emailVerified, isAnonymous, tenantId
  // }
  return <UserContext.Provider value={[state, setState]}>{children}</UserContext.Provider>
}

export { UserContext, UserProvider }
