import React from "react"
import MUITextField from "@material-ui/core/TextField"
import { makeStyles } from "@material-ui/core/styles"
import { useForm, useField } from "react-final-form"

const useStyles = makeStyles(theme => ({
  text: {
    marginBottom: theme.spacing(1)
  }
}))

const TextField = props => {
  const classes = useStyles()
  const { change } = useForm()
  const {
    input: { onFocus, onBlur },
    meta: { touched, error }
  } = useField(props.name, {
    subscription: { touched: true, error: true, value: true }
  })
  const override = props.override === "true"

  return (
    <MUITextField
      error={override ? override : error && touched}
      className={classes.text}
      onChange={e => change(e.currentTarget.name, e.currentTarget.value)}
      autoComplete={props.name}
      onBlur={e => onBlur(e)}
      onFocus={onFocus}
      {...props}
      variant='outlined'
      label={error && touched && props.label ? `${props.label} *` : props.label}>
      {props.children}
    </MUITextField>
  )
}

export default TextField
