import React from "react"
import MUITextField from "@material-ui/core/TextField"
import { makeStyles } from "@material-ui/core"

const useStyles = makeStyles(theme => ({
	field: {
		background: theme.baseColors.white,
		marginBottom: theme.spacing(0.5)
	}
}))
const TextField = ({ name, value, ...props }) => {
	const classes = useStyles()

	return (
		<MUITextField
			className={classes.field}
			variant='outlined'
			fullWidth
			inputProps={{ name, value }}
			{...props}
		/>
	)
}

export default TextField
