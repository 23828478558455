import React from "react"

import Main from "./pages/main"
import Login from "./pages/login/login"
import Dashboard from "./pages/dashboard/dashboard"
import Signup from "./pages/signup"
import Welcome from "./pages/welcome/welcome"
import Terms from "./pages/terms/terms"
import ConfirmSignup from "./pages/login/ConfirmationLogin"
import PlayerNew from "./components/Player/PlayerNew"
import Password from "./pages/ForgotPassword"

import firebase from "firebase/app"
import { Provider } from "react-redux"
import { ToastContainer } from "react-toastify"
import { CssBaseline } from "@material-ui/core"
import { createFirestoreInstance } from "redux-firestore"
import configureStore from "./config/store/configureStore"
import { Route, Switch, Redirect } from "react-router-dom"
import { rrfConfig, firebaseConfig } from "./config/store/firebase/firebaseConfig"
import { ReactReduxFirebaseProvider } from "react-redux-firebase"
import { ThemeProvider, createMuiTheme, makeStyles } from "@material-ui/core/styles"
import MenloRegularTTF from "assets/fonts/Menlo-Regular.ttf"
import useAuth from "hooks/useAuth"

import "firebase/firestore"
import "firebase/auth"
import "firebase/storage"
import "./assets/css/style.css"
import "react-toastify/dist/ReactToastify.css"

firebase.initializeApp(firebaseConfig)
firebase.firestore()
firebase.storage()

const rrfProps = {
  firebase,
  config: rrfConfig,
  dispatch: configureStore.dispatch,
  createFirestoreInstance
}

const menloRegular = {
  fontFamily: "Menlo-Regular",
  fontStyle: "normal",
  fontDisplay: "swap",
  fontWeight: 400,
  src: `
    local('Menlo'),
    local('Menlo-Regular'),
    url(${MenloRegularTTF}) format('ttf')
  `,
  unicodeRange:
    "U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF"
}

const theme = createMuiTheme({
  typography: {
    fontFamily: "Menlo-Regular"
  },
  overrides: {
    MuiCssBaseline: {
      "@global": {
        "@font-face": [menloRegular]
      }
    }
  },
  palette: {
    primary: {
      main: "#2f33ee"
    },
    secondary: {
      main: "#ffffff"
    }
  },
  baseColors: {
    blue: "#2f33ee",
    lightBlue: "#5456e8",
    submit: "green",
    white: "#fff",
    green: "#49AF44",
    danger: "red",
    lightgrey: "#969595",
    dark: "#212121",
    facebook: "#3C5B96",
    twitter: "#37AADE",
    google: "#D84C3E",
    playerBackground: "#878787",
    offWhite: "#fffdee"
  },
  spacing: factor => `${1.2 * factor}rem`
})

const useStyles = makeStyles(() => ({
  toastContainer: {
    textAlign: "center"
  }
}))

const App = props => {
  const { user } = useAuth(firebase.auth())
  const classes = useStyles()

  return (
    <Provider store={configureStore}>
      <ReactReduxFirebaseProvider {...rrfProps}>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <Switch>
            {user && <Redirect from='/' to='/dashboard' exact />}
            <Route path='/' component={Main} exact />
            <Route path='/login' component={Login} />
            <Route path='/dashboard' component={Dashboard} />
            <Route path='/forgot-password' component={Password} />
            <Route path='/signup_success' component={ConfirmSignup} />
            <Route path='/video/:id' component={PlayerNew} />
            <Route path='/signup' component={Signup} />
            <Route path='/welcome' component={Welcome} />
            <Route path='/terms' component={Terms} />
            <Route path='/*' component={Main} />
          </Switch>
          <ToastContainer toastClassName={classes.toastContainer} closeButton={false} />
        </ThemeProvider>
      </ReactReduxFirebaseProvider>
    </Provider>
  )
}

export default App
