import React, { useState, useContext } from "react"
import { Link } from "react-router-dom"
import useAuth from "hooks/useAuth"
// MUI
import withWidth from "@material-ui/core/withWidth"
import AppBar from "@material-ui/core/AppBar"
import IconButton from "@material-ui/core/IconButton"
import Menu from "@material-ui/core/Menu"
import MenuItem from "@material-ui/core/MenuItem"
import Logo from "components/images/logo"
import MenuIcon from "@material-ui/icons/Menu"
import SearchIcon from "@material-ui/icons/Search"
import MUIToolbar from "@material-ui/core/Toolbar"
import Tabs from "@material-ui/core/Tabs"
import Tab from "@material-ui/core/Tab"
// actions * firebase
import * as actions from "config/store/actions/actions"
import * as firebase from "firebase/app"
//context
import { VideoContext } from "context/videoContext"
import { makeStyles } from "@material-ui/core"

const useStyles = makeStyles({
  search: {
    marginRight: ".5rem",
    marginLeft: "auto"
  },
  tab: {
    "&:focus": {
      outline: "none"
    }
  },
  menuPaper: {
    minWidth: "150px"
  },
  menuList: {
    textAlign: "right"
  }
})

const Toolbar = ({ history, width, ...props }) => {
  const { user } = useAuth(firebase.auth())
  const [{ active, videoUpload, categories }, dispatch] = useContext(VideoContext)
  const [anchorEl, setAnchorEl] = useState(null)
  const classes = useStyles()
  const handleMenu = e => {
    if (Boolean(anchorEl)) {
      setAnchorEl(null)
    } else {
      setAnchorEl(e.currentTarget)
    }
  }
  const handleSignout = () => {
    if (videoUpload) {
      handleUpload()
    }
    firebase.auth().signOut()
    setAnchorEl(null)
  }
  const handleUpload = () => dispatch({ type: actions.UPLOAD_VIDEO })
  const handleSearch = () => dispatch({ type: actions.SEARCH_TOGGLE })
  const handleTabs = (e, tabIndex) => {
    dispatch({ type: actions.ACTIVE_SIDEBAR, payload: tabIndex })
  }

  if (props.logoOnly) {
    return (
      <AppBar position='fixed'>
        <MUIToolbar
          style={{
            display: "flex",
            background: "#2f33ee"
          }}>
          <Logo />
        </MUIToolbar>
      </AppBar>
    )
  }

  return (
    <AppBar position='fixed'>
      <MUIToolbar
        style={{
          display: "flex",
          background: "#2f33ee"
        }}>
        <Logo />
        {!(width === "xs") && (
          <Tabs
            className={classes.tablist}
            variant='scrollable'
            onChange={handleTabs}
            value={active}
            indicatorColor='secondary'>
            {categories.map((e, i) => (
              <Tab key={e.name} label={e.name} className={classes.tab} disableRipple />
            ))}
          </Tabs>
        )}
        <IconButton
          className={classes.search}
          edge='end'
          color='inherit'
          aria-label='menu'
          onClick={handleSearch}>
          <SearchIcon />
        </IconButton>
        <IconButton edge='end' color='inherit' aria-label='menu' onClick={handleMenu}>
          <MenuIcon />
        </IconButton>
        {user ? (
          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
            onClick={handleMenu}
            getContentAnchorEl={null}
            onClose={handleMenu}>
            <MenuItem onClick={handleUpload}>Video Upload</MenuItem>
            <MenuItem onClick={handleSignout}>Sign Out</MenuItem>
            <MenuItem>Help</MenuItem>
          </Menu>
        ) : (
          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
            getContentAnchorEl={null}
            classes={{
              paper: classes.menuPaper,
              list: classes.menuList
            }}
            // paperProps={{ minWidth: "150px" }}
            onClose={handleMenu}>
            <Link to='/login'>
              <MenuItem>Login</MenuItem>
            </Link>
            {/* <Link to="/signup"><MenuItem>Register</MenuItem></Link> */}
            <MenuItem>Help</MenuItem>
          </Menu>
        )}
      </MUIToolbar>
    </AppBar>
  )
}

export default withWidth()(Toolbar)
