import React, { useContext } from "react"
import MovieList from "components/MovieList/MovieList"
import SearchBar from "components/search/searchBar"
import VideoUploadForm from "components/forms/VideoUpload"
import Toolbar from "components/nav/toolbar"
import { VideoProvider } from "context/videoContext"
import { VideoContext } from "context/videoContext"
import { makeStyles } from "@material-ui/core"
import clsx from "clsx"
import "./dashboard.css"

const useStyles = makeStyles(theme => ({
  dashboard: {
    background: theme.baseColors.dark,
    paddingTop: "64px",
    height: "100vh",
    overflowY: "scroll"
  }
}))

export default function Dashboard() {
  const [state] = useContext(VideoContext)
  const classes = useStyles({ toggle: state.searchToggle })

  return (
    <VideoProvider>
      <Toolbar />
      <div className={clsx("dashboard", classes.dashboard)}>
        {state.videoUpload ? (
          <VideoUploadForm />
        ) : (
          <React.Fragment>
            <SearchBar />
            <MovieList />
          </React.Fragment>
        )}
      </div>
    </VideoProvider>
  )
}
