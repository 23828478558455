// import React, { useContext } from "react"
import React from "react"
import Step2 from "./signupSteps/step2"
import Paper from "@material-ui/core/Paper"
import Toolbar from "components/nav/toolbar"
import { makeStyles } from "@material-ui/core/styles"

import "./signup.css"

const useStyles = makeStyles(theme => ({
  stepper: {
    [theme.breakpoints.only("xs")]: {
      padding: "1rem 0"
    }
  },
  formContainer: {
    display: "flex",
    flexFlow: "column",
    justifyContent: "center",
    alignItems: "center",
    padding: theme.spacing(1),
    boxShadow: "0 0 20px darkgrey",
    marginTop: "80px",
    [theme.breakpoints.up("sm")]: {
      padding: theme.spacing(2),
      flexFlow: "row",
      margin: "auto",
      maxWidth: "720px"
    },
    [theme.breakpoints.up("md")]: {
      width: "70%",
      maxWidth: "922px"
    }
  }
}))

export default function Signup(props) {
  const classes = useStyles()

  return (
    <React.Fragment>
      <Toolbar logoOnly />
      <Paper className={classes.formContainer}>
        <Step2 />
      </Paper>
    </React.Fragment>
  )
}
