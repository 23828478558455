export const firebaseConfig = {
  apiKey: "AIzaSyCV34D3lF3JViHIDtz9dkZbLjFkk-HarV8",
  authDomain: "test-798e3.firebaseapp.com",
  databaseURL: "https://test-798e3.firebaseio.com",
  projectId: "test-798e3",
  storageBucket: "test-798e3.appspot.com",
  messagingSenderId: "802651636449",
  appId: "1:802651636449:web:3ee95abc72253176"
};

export const rrfConfig = {
  userProfile: "users",
  useFirestoreForProfile: true
};