import React, { useContext, useEffect, useState } from "react"
import { compose } from "redux"
import { connect } from "react-redux"
import { firestoreConnect } from "react-redux-firebase"
import * as actions from "config/store/actions/actions"
import { VideoContext } from "context/videoContext"
import { Link } from "react-router-dom"
import cuid from "cuid"

//MUI
import { makeStyles } from "@material-ui/core"
import withWidth, { isWidthUp } from "@material-ui/core/withWidth"
import GridList from "@material-ui/core/GridList"
import CircularProgress from "@material-ui/core/CircularProgress"
import GridListTile from "@material-ui/core/GridListTile"
import GridListTileBar from "@material-ui/core/GridListTileBar"
import ListItem from "@material-ui/core/ListItem"
import ListItemText from "@material-ui/core/ListItemText"
import Dialog from "@material-ui/core/Dialog"
import CloseIcon from "@material-ui/icons/Close"

const useStyles = makeStyles(theme => ({
  appBar: {
    position: "relative"
  },
  gridList: props => ({
    maxWidth:
      props.width === "xs"
        ? "400px"
        : props.width === "sm"
        ? "700px"
        : props.width === "md"
        ? "1000px"
        : props.width === "lg"
        ? "1350px"
        : "1900px"
  }),
  tileBar: {
    display: "none"
  },
  video: {
    cursor: "pointer",
    "& .MuiGridListTile-tile": {
      borderRadius: "5px"
    },
    "& img": {
      display: "none"
    }
  },
  miniModal: {
    margin: "auto 0",
    background: "rgba(0, 0, 0, 0.4)"
  }
}))

const MovieList = ({ width, videos }) => {
  const [cntxt, setCntxt] = useContext(VideoContext)
  const [screenSize, setScreenSize] = useState("")
  const classes = useStyles({ width: screenSize })
  const colWidth = isWidthUp("xl", width)
    ? 5
    : isWidthUp("lg", width)
    ? 4
    : isWidthUp("md", width)
    ? 3
    : isWidthUp("sm", width)
    ? 2
    : 1
  const filtered = videos ? filterVideos(videos, cntxt) : []

  const handleMiniModal = tabIndex => {
    setCntxt({ type: actions.ACTIVE_SIDEBAR, payload: tabIndex })
    setCntxt({ type: actions.MINI_MODAL })
  }

  useEffect(() => {
    setScreenSize(width)
  }, [width])

  return (
    <div>
      {cntxt.categories.length > 0 && (
        <GridList
          className={classes.gridList}
          spacing={10}
          cellHeight='auto'
          cols={colWidth}
          style={{
            margin: "auto",
            filter: cntxt.miniModal ? "blur(4px)" : "none"
          }}>
          {filtered.length > 0 ? (
            filtered.map((video, i) => {
              const tileID = cuid()
              return (
                <GridListTile
                  id={tileID}
                  key={video.imageUrl + i}
                  col={1}
                  className={classes.video}>
                  <div
                    className='circular'
                    style={{
                      minHeight: "195px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      background: "#ffffff0f"
                    }}>
                    <CircularProgress />
                  </div>
                  <Link to={`/video/${video.id}`}>
                    <img
                      src={video.imageUrl}
                      alt={video.category}
                      onLoad={() => {
                        const myTile = document.getElementById(tileID)
                        const progressBar = myTile.querySelector("div.circular")
                        const myImage = myTile.querySelector("img")
                        const myTileBar = myTile.querySelector("div.MuiGridListTileBar-root")
                        progressBar.style.display = "none"
                        myImage.style.display = "block"
                        myTileBar.style.display = "block"
                      }}
                    />
                  </Link>
                  <GridListTileBar
                    className={classes.tileBar}
                    title={video.title}
                    subtitle={
                      <div>
                        <span>{video.info}</span>
                        {video.watchCount ? (
                          <div style={{ marginTop: "4px" }}>
                            {video.watchCount === 1
                              ? `${video.watchCount} view`
                              : `${video.watchCount} views`}
                          </div>
                        ) : null}
                      </div>
                    }
                  />
                  )}
                </GridListTile>
              )
            })
          ) : (
            <div style={{ width: "100%", padding: "1rem 2rem" }}>no videos were found</div>
          )}
        </GridList>
      )}
      <Dialog
        PaperProps={{
          style: { background: "rgba(0,0,0,0.4)" }
        }}
        className={classes.miniModal}
        fullScreen
        open={cntxt.miniModal}
        onClose={() => setCntxt({ type: actions.MINI_MODAL })}>
        <div className={classes.miniModal}>
          {cntxt.categories.map((e, i) => (
            <div key={e.name}>
              <ListItem button onClick={() => handleMiniModal(i)}>
                <ListItemText style={{ textAlign: "center", color: "white" }}>
                  {e.name}
                </ListItemText>
              </ListItem>
            </div>
          ))}
          <ListItem button onClick={() => setCntxt({ type: actions.MINI_MODAL })}>
            <ListItemText style={{ textAlign: "center", color: "white" }}>
              <CloseIcon />
            </ListItemText>
          </ListItem>
        </div>
      </Dialog>
    </div>
  )
}

const filterVideos = (videos, cntxt, cb) => {
  if (!cntxt.categories || cntxt.categories.length < 1) return videos
  return videos.filter(v => {
    const title = v.title.trim().toLowerCase()
    const info = v.info.trim().toLowerCase()
    const isActiveCategory = cntxt.categories[cntxt.active].id === v.category
    if (cntxt.searchQuery && cntxt.searchQuery.length > 0) {
      const query = cntxt.searchQuery.trim().toLowerCase()
      if (title.includes(query) | info.includes(query) && isActiveCategory) {
        return true
      }
    } else {
      if (isActiveCategory) {
        return true
      }
    }
    return false
  })
}

const mapStateToProps = state => ({
  videos: state.firestore.ordered.Video
})

export default compose(
  firestoreConnect([{ collection: "Video" }]),
  connect(mapStateToProps)
)(withWidth()(MovieList))
