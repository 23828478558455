import React from 'react'
import clsx from 'clsx'
import CircularProgress from '@material-ui/core/CircularProgress'
import defaultImagePlaceholder from "assets/images/imagePlaceholder.png";
import PriorityHighIcon from '@material-ui/icons/PriorityHigh'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'


const Images = ({ handleUpload, classes, loading, progress, previews, errors }) => {
  return (
    <React.Fragment>
      <input
        accept="image/*"
        style={{ display: "none" }}
        id="raised-button-thumbnail"
        name="thumbnail"
        type="file"
        onChange={handleUpload}
      />
    <label htmlFor="raised-button-thumbnail" className={classes.uploadImagesContainer}>
      {loading.thumb ? (
        <div style={{ textAlign: "center", padding: "1rem" }}>
          <CircularProgress
            className={classes.progress}
            value={progress.image}
          />
        </div>
      ) : previews.imagePreview ? (
        <img
          className={classes.image}
          src={previews.imagePreview}
          alt="Thumbnail preview"
        />
      ) : (
        <img src={defaultImagePlaceholder} alt="placeholder" />
      )}
      <Box style={{ textAlign: "center", position: "relative" }}>
        <Button
          variant="outlined"
          component="span"
          className={clsx("image-button", classes.imageButton)}
        >
          Upload Thumbnail
        </Button>
        {errors.image && (
          <PriorityHighIcon className={classes.icon} />
        )}
      </Box>
    </label>
    {/* UPLOAD POSTER */}
    <input
      accept="image/*"
      style={{ display: "none" }}
      id="raised-button-poster"
      name="poster"
      type="file"
      onChange={handleUpload}
    />
    <label htmlFor="raised-button-poster" className={classes.uploadImagesContainer}>
      {loading.poster ? (
        <div style={{ textAlign: "center", padding: "1rem" }}>
          <CircularProgress
            className={classes.progress}
            value={progress.image}
          />
        </div>
      ) : previews.posterPreview ? (
        <img
          className={classes.image}
          src={previews.posterPreview}
          alt="Poster preview"
        />
      ) : (
        <img src={defaultImagePlaceholder} alt="placeholder" />
      )}
      <Box style={{ textAlign: "center", position: "relative" }}>
        <Button
          variant="outlined"
          component="span"
          className={clsx( "image-button", classes.imageButton, "posterBtn" )}
        >
          Upload Poster
        </Button>
        {errors.poster && (
          <PriorityHighIcon className={classes.icon} />
        )}
      </Box>
    </label>
  </React.Fragment>
  )}

export default Images