import React from "react"
import Button from "@material-ui/core/Button"
import PriorityHighIcon from "@material-ui/icons/PriorityHigh"
import CircularProgress from "@material-ui/core/CircularProgress"
import CheckCircleIcon from "@material-ui/icons/CheckCircle"
import clsx from "clsx"

const Video = ({
  errors,
  classes,
  videoName,
  handleUpload,
  loading,
  progress,
  has
}) => (
  <React.Fragment>
    <input
      accept='video/*'
      style={{ display: "none" }}
      id='raised-button-video'
      name='video'
      type='file'
      onChange={handleUpload}
    />
    <label
      htmlFor='raised-button-video'
      className={classes.videoUploadContainer}>
      <div
        style={{
          width: "100%",
          display: "flex",
          alignItems: "center",
          position: "relative"
        }}>
        <Button
          variant='outlined'
          component='span'
          className={clsx("videoUpload-button", classes.uploadVideoBtn)}>
          Upload Video
        </Button>
        {errors.video && (
          <PriorityHighIcon
            className={classes.icon}
            style={{ right: "-29", bottom: "6" }}
          />
        )}
        {loading.video ? (
          <span style={{ marginLeft: "1rem" }}>
            <CircularProgress variant='static' value={progress.video} />
          </span>
        ) : has.video ? (
          <span style={{ padding: "1rem" }}>
            <CheckCircleIcon style={{ color: "green" }} />
          </span>
        ) : null}
      </div>
      {has.video && <div style={{ marginTop: ".6rem" }}>{videoName}</div>}
    </label>
  </React.Fragment>
)

export default Video
