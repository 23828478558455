import React from "react"
import MUIButton from "@material-ui/core/Button"
import { makeStyles } from "@material-ui/core"

const useStyles = makeStyles(({ spacing, baseColors }) => ({
  btn: props => {
    let color
    if (props.gray) {
      color = baseColors.lightGrey
    } else {
      color = baseColors.blue
    }
    return {
      background: color,
      color: baseColors.white,
      marginRight: spacing(1),
      border: "none",
      boxShadow: "-1px 1px 4px grey"
    }
  }
}))

const Button = props => {
  const classes = useStyles(props)
  return <MUIButton className={classes.btn} variant='outlined' {...props} />
}

export default Button
