import React, { useState, useEffect, useContext } from "react"
import cuid from "cuid"
import clsx from "clsx"
//styles and assets
import Modal from "./Modal"
import TextFields from "./TextFields"
import Video from "./video"
import Images from "./images"
// material ui
import { Box, Card, Container, makeStyles } from "@material-ui/core"
import IconButton from "@material-ui/core/IconButton"
import CloseIcon from "@material-ui/icons/Close"
import Button from "components/material/Button"
import { useTheme } from "@material-ui/styles"
import Snackbar from "components/snackbar/snackbar"
import { VideoContext } from "context/videoContext"
import * as actions from "config/store/actions/actions"
// firebase
import * as firebase from "firebase/app"

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: ".5rem",
    minWidth: "60%",
    color: "yellow"
  },
  uploadFormContainer: {
    textAlign: "center",
    boxShadow: "none",
    height: "100%"
  },
  titleContainer: {
    margin: "2rem auto",
    fontFamily: "Montserrat, sans-serif"
  },
  fieldsContainer: {
    display: "flex",
    maxWidth: "600px",
    margin: "auto",
    [theme.breakpoints.down("xs")]: {
      flexFlow: "column"
    }
  },
  uploadImagesContainer: {
    display: "flex",
    flexFlow: "column"
  },
  uploads: {
    width: "unset",
    minWidth: "315px",
    [theme.breakpoints.down("xs")]: {
      display: "flex",
      margin: "0 1rem",
      justifyContent: "space-evenly"
    }
  },
  imageButton: {
    marginTop: ".5rem",
    minWidth: "60%"
  },
  videoUploadContainer: {
    marginTop: "1rem",
    borderRadius: "5px"
  },
  uploadVideoBtn: {
    color: theme.baseColors.white,
    background: theme.baseColors.blue,
    textTransform: "none",
    padding: ".7rem 2rem",
    border: "none"
    // "& :hover": {
    //   backgroundColor: theme.baseColors.lightBlue,
    //   borderRadius: "5px"
    // }
    // "& :hover": {
    //   border: "1px solid grey"
    // }
  },
  textFields: {
    textAlign: "left",
    [theme.breakpoints.down("xs")]: {
      margin: " 0 1rem",
      textAlign: "center"
    }
  },
  submit: {
    color: theme.baseColors.white,
    background: theme.baseColors.submit,
    padding: ".4rem 3rem",
    textTransform: "none"
  },
  submitContainer: {
    margin: "2rem auto"
  },
  menu: {
    width: 200
  },
  icon: {
    fill: theme.baseColors.danger,
    position: "absolute",
    right: "20",
    bottom: "0",
    marginBottom: ".3rem"
  },
  close: {
    position: "absolute",
    top: 11,
    right: 14
  }
}))

export default function Admin() {
  const [, dispatch] = useContext(VideoContext)
  const classes = useStyles()
  const theme = useTheme()
  const [userId, setUserId] = useState(null)
  const [formFields, setField] = useState({ title: "", category: "", info: "" })
  const [errors, setErrors] = useState({
    title: false,
    category: false,
    info: false,
    image: false,
    poster: false,
    video: false
  })
  const [videoName, setVideoName] = useState(null)
  const [previews, setPreview] = useState({
    imagePreview: null,
    posterPreview: null
  })
  const [progress, setProgress] = useState({
    image: 100,
    video: 100,
    poster: 100
  })
  const [loading, setLoading] = useState({
    thumb: false,
    poster: false,
    video: false
  })
  const [has, setHas] = useState({ video: false, image: false, poster: false })
  const [downloadURLs, setDownloadURL] = useState({
    image: null,
    video: null,
    poster: null
  })
  const [snack, setSnack] = useState({ open: false, message: "" })
  const [modal, setModal] = useState(false)

  // give user an id
  useEffect(() => {
    setUserId(cuid())
  }, [])

  const handleInput = ({ target: { name, value } }) => {
    if (errors[name]) {
      setErrors({ ...errors, [name]: false })
    }
    setField({ ...formFields, [name]: value })
  }
  const handleUpload = e => {
    if (!e.target.name) {
      return
    }
    // handles posters, thumbnails and videos
    const { title } = formFields
    const file = e.target.files[0]
    let filename = title ? title : file.name
    const poster = e.target.name === "poster"
    const thumbnail = e.target.name === "thumbnail"
    const video = e.target.name === "video"
    const dir = thumbnail || poster ? "VideoImages" : "test-videos"
    if (poster) {
      setErrors({ ...errors, poster: false })
      setPreview(p => ({ ...p, posterPreview: URL.createObjectURL(file) }))
      filename = "poster-" + filename
    } else if (thumbnail) {
      setErrors({ ...errors, image: false })
      setPreview(p => ({ ...p, imagePreview: URL.createObjectURL(file) }))
      filename = "thumbnail-" + filename
    } else if (video) {
      setErrors({ ...errors, video: false })
    }
    const regex = /\s/gi
    filename = filename.toLowerCase().replace(regex, "")
    let storageRef
    if (poster || thumbnail) {
      storageRef = firebase.storage().ref(`${dir}/${userId}/${filename}`)
    } else {
      storageRef = firebase.storage().ref(`${dir}/${filename}`)
    }

    const task = storageRef.put(file)
    task
      .then(e => {
        //set imageURL
        // firebase.collection
        if (e.ref.fullPath.indexOf("poster") !== -1) {
          setLoading({ ...loading, poster: false })
        } else if (e.ref.fullPath.indexOf("thumbnail") !== -1) {
          setLoading({ ...loading, thumb: false })
        } else {
          setVideoName(e.ref.name)
          setLoading({ ...loading, video: false })
        }
      })
      .catch(e => console.log("error", e))

    task.on(
      "state_changed",
      function progress(snapshot) {
        var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        if (thumbnail) {
          setProgress({ ...progress, image: progress })
          setLoading({ ...loading, thumb: true })
        } else if (poster) {
          setProgress({ ...progress, poster: progress })
          setLoading({ ...loading, poster: true })
        } else if (video) {
          setProgress({ ...progress, video: progress })
          setLoading({ ...loading, video: true })
        }
      },
      function error(error) {
        console.log("error", error)
      },
      function complete() {
        task.snapshot.ref.getDownloadURL().then(downloadURL => {
          if (poster || thumbnail) {
            poster
              ? setHas(obj => ({ ...obj, poster: true }))
              : setHas(obj => ({ ...obj, image: true }))
            poster
              ? setDownloadURL({ ...downloadURLs, poster: downloadURL })
              : setDownloadURL({ ...downloadURLs, image: downloadURL })
          } else if (video) {
            setHas(obj => ({ ...obj, video: true }))
            setDownloadURL({ ...downloadURLs, video: downloadURL })
          }
        })
      }
    )
  }

  const handleSubmit = () => {
    const { info, category, title } = formFields
    const { video: videoURL, image: imageURL, poster: posterURL } = downloadURLs
    const tb = title.length > 0
    const ib = info.length > 0
    const cb = category.length > 0
    if (!cb || !ib || !tb || !has.poster || !has.video || !has.image) {
      setErrors({
        title: !tb,
        category: !cb,
        info: !ib,
        image: !has.image,
        poster: !has.poster,
        video: !has.video
      })
      openSnack("Please completely fill out the form.")
      return
    } else {
      setErrors(curr => false)
    }
    if (has.image && has.video && has.poster && tb && ib && cb) {
      firebase
        .firestore()
        .collection("test-Video")
        .add({
          category,
          info,
          title,
          videoURL,
          imageURL,
          posterURL,
          createdOn: firebase.firestore.FieldValue.serverTimestamp()
        })
        .then(res => {
          //load modal "Video asset will be available once encoding process is complete."
          setModal(true)
        })
    }
  }
  const handleModalClose = () => {
    setModal(false)
    dispatch({ type: actions.UPLOAD_VIDEO })
  }
  const openSnack = message => {
    setSnack({ open: true, message: message })
  }
  const closeVideoUpload = () => dispatch({ type: actions.UPLOAD_VIDEO })

  return (
    <div className='upload-form'>
      <Card className={classes.uploadFormContainer}>
        <Container className={classes.titleContainer}>
          <h2>
            Video Upload Form
            <IconButton
              className={classes.close}
              color='inherit'
              aria-label='menu'
              onClick={closeVideoUpload}>
              <CloseIcon />
            </IconButton>
          </h2>
        </Container>
        <Box
          className={clsx(
            classes.fieldsContainer,
            "upload-inputFields-container"
          )}>
          <div className={clsx(classes.textFields, "textFields")}>
            <TextFields
              handleInput={handleInput}
              errors={errors}
              formFields={formFields}
              classes={classes}
            />
            <Video
              errors={errors}
              classes={classes}
              videoName={videoName}
              handleUpload={handleUpload}
              loading={loading}
              progress={progress}
              has={has}
            />
          </div>
          <div className={clsx(classes.uploads, "uploads")}>
            <Images
              handleUpload={handleUpload}
              classes={classes}
              loading={loading}
              progress={progress}
              previews={previews}
              errors={errors}
            />
          </div>
        </Box>
        <Container className={classes.submitContainer}>
          <Button
            onClick={handleSubmit}
            className={clsx("submit-button", classes.submit)}>
            Submit
          </Button>
        </Container>
      </Card>
      <Modal
        modal={modal}
        setModal={setModal}
        handleModalClose={handleModalClose}
      />
      <Snackbar
        style={{ background: theme.backgroundColor }}
        m={snack.message}
        open={snack.open}
        closeFn={() => setSnack({ ...snack, open: false })}
      />
    </div>
  )
}
