import React from "react"
// import React, { useState } from "react"
// import { makeStyles } from "@material-ui/core/styles"
// import Divider from "@material-ui/core/Divider"
import SignUpForm from "./SignUpForm"
// import SocialSignUp from "./SocialSignUp"
import "./step2.css"

// const useStyles = makeStyles(theme => ({
//   divider: {
//     [theme.breakpoints.only("xs")]: {
//       display: "none"
//     }
//   }
// }))

const StepTwo = props => {
  // const classes = useStyles()

  return (
    <React.Fragment>
      <SignUpForm />
      {/* <Divider orientation='vertical' variant='middle' className={classes.divider} /> */}
      {/* <SocialSignUp /> */}
    </React.Fragment>
  )
}

export default StepTwo
