import React, { useEffect } from "react"
import { Link } from "react-router-dom"
import useAuth from "hooks/useAuth"
import validate from "./loginValidation"
import { toast } from "react-toastify"
import Paper from "@material-ui/core/Paper"
import Grid from "@material-ui/core/Grid"
import Button from "components/button/button"
import { makeStyles } from "@material-ui/core"
import { Form } from "react-final-form"
import TextField from "components/finalForm/TextField"
import firebase from "firebase/app"
import Logo from "components/images/logo"

const useStyles = makeStyles(theme => ({
  logo: {
    textAlign: "center",
    marginBottom: theme.spacing(2)
  },
  grid: {
    background: theme.baseColors.blue,
    height: "100vh",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    margin: "auto"
  },
  paper: {
    padding: theme.spacing(2)
  },
  btnContainer: {
    textAlign: "center"
  },
  field: {
    marginBottom: theme.spacing(1)
  }
}))

export default function Login({ history, ...props }) {
  const classes = useStyles()
  const { user } = useAuth(firebase.auth())

  const handleSubmit = ({ email, password }) => {
    firebase
      .auth()
      .signInWithEmailAndPassword(email, password)
      .then(() => {
        history.push("/dashboard")
      })
      .catch(e => {
        toast.error("There was an error logging in. Please check your credentials and try again.")
        console.log("err", e)
      })
  }

  useEffect(() => {
    if (user != null) {
      history.push("/dashboard")
    }
  }, [user, history])

  return (
    <Grid className={classes.grid} container>
      <Grid item>
        <Paper className={classes.paper}>
          <Logo className={classes.logo} black='true' />
          <Form validate={validate} onSubmit={handleSubmit}>
            {({ handleSubmit, values, errors }) => {
              return (
                <form
                  onSubmit={e => {
                    e.preventDefault()
                    if (Object.keys(errors).length > 0) {
                      Object.values(errors).forEach(error => toast.info(error))
                    } else {
                      handleSubmit()
                    }
                  }}>
                  <TextField name='email' inputProps={{ placeholder: "Email" }} fullWidth />
                  <TextField
                    className={classes.field}
                    name='password'
                    type='password'
                    inputProps={{ placeholder: "Password" }}
                    fullWidth
                  />
                  <Grid container spacing={1} justify='center'>
                    <Grid item>
                      <Button type='submit'>Login</Button>
                    </Grid>
                    <Grid item>
                      <Link to='/signup'>
                        <Button>Register</Button>
                      </Link>
                    </Grid>
                  </Grid>
                  <div style={{ textAlign: "center", paddingTop: "1rem" }}>
                    <Link to='/forgot-password'>Forgot Password?</Link>
                  </div>
                </form>
              )
            }}
          </Form>
        </Paper>
      </Grid>
    </Grid>
  )
}
