import React from "react"
import { makeStyles } from "@material-ui/core/styles"
import MUIButton from "@material-ui/core/Button"
import clsx from "clsx"

const useStyles = makeStyles(theme => ({
  root: props => {
    const background = {
      twitter: theme.baseColors.twitter,
      google: theme.baseColors.google,
      facebook: theme.baseColors.facebook,
      submit: theme.baseColors.green,
      default: "white"
    }

    return {
      border: "1px solid rgb(231, 231, 231)",
      borderRadius: "6px",
      padding: theme.spacing(0.7, 2.5),
      color: theme.baseColors.white,
      maxHeight: "50px",
      width: "150px",
      background: props.bType ? background[props.bType] : theme.baseColors.blue,
      borderColor: "rgb(249, 249, 249)",
      textTransform: "none",
      "& i": {
        marginRight: "auto",
        position: "absolute",
        left: theme.spacing(1),
        [theme.breakpoints.down("500")]: {
          display: "block",
          position: "static"
        }
      },
      "&:focus": {
        border: "none",
        outline: "none"
      },
      "&:hover": {
        background: props.social ? background[props.social] : "lightgrey"
      }
    }
  }
}))

export default function Button({ children, bType, ...props }) {
  const classes = useStyles({ ...props, bType })
  const social = {
    facebook: classes.facebook,
    twitter: classes.twitter,
    google: classes.google
  }

  return (
    <MUIButton className={clsx(classes.root, social[bType])} {...props}>
      {children}
    </MUIButton>
  )
}
