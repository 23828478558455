import React, { useState, useEffect } from 'react'
import TextField from 'components/material/TextField'
import MenuItem from '@material-ui/core/MenuItem'
import clsx from 'clsx'
import * as firebase from 'firebase/app'

const TextFields = ({handleInput, errors, formFields, classes }) => {
  const [categories, setCategories] = useState([])

  // set categories from db
  useEffect(() => {
    let myCategories = [];
    const fb = firebase.firestore().collection("categories");
    fb.get().then(docs => {
      docs.forEach(doc => {
        myCategories.push({ name: doc.data().name, id: doc.id });
      });
      setCategories(myCategories);
    });
  }, []);

  return (
		<React.Fragment>
			<TextField
				error={errors.title}
				style={{marginBottom: 0}}
				placeholder="Title"
				id="outlined-name"
				label="Title"
				onChange={handleInput}
				name="title"
				value={formFields.title}
			/>
			<TextField
				error={errors.category}
				select
				id="outlined-category"
				name="category"
				label="Category"
				margin="normal"
				onChange={handleInput}
				style={{ marginBottom: "0" }}
				value={formFields.category}
			>
				<MenuItem value=""></MenuItem>
				{categories.map(category => (
					<MenuItem key={category.id} value={category.name}>
						{category.name}
					</MenuItem>
				))}
			</TextField>
			<TextField
				className={clsx(classes.multiLine, "multiText")}
				error={errors.info}
				multiline
				placeholder="Info"
				onChange={handleInput}
				label="Info"
				name="info"
				value={formFields.info }
			/>
    </React.Fragment>
)}
export default TextFields