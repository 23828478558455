import React, { useState, useContext } from "react"
import SearchIcon from "assets/images/mobile-search-icon.png"
import {
  Avatar,
  Button,
  Container,
  InputBase,
  ListSubheader,
  makeStyles,
  Radio,
  Paper
} from "@material-ui/core"
import withWidth from "@material-ui/core/withWidth"
import RadioButtonCheckedIcon from "@material-ui/icons/RadioButtonChecked"
import RadioButtonUncheckedIcon from "@material-ui/icons/RadioButtonUnchecked"
import ExpandMore from "@material-ui/icons/ExpandMore"
import Chip from "@material-ui/core/Chip"
import { VideoContext } from "context/videoContext"
import clsx from "clsx"
import * as actions from "config/store/actions/actions"
import "./searchBar.css"

const useStyles = makeStyles({
  root: {
    display: "flex",
    padding: "2px 4px",
    borderRadius: "0",
    height: "60px",
    boxShadow: "none",
    borderBottom: "1px solid lightgrey",
    position: "relative"
  },
  borderRight: {
    borderRight: "1px dashed lightgrey"
  },
  buttonLeft: {
    background: "green",
    color: "white",
    marginRight: "1rem",
    padding: "5px 6rem",
    textTransform: "none"
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "center",
    paddingBottom: "1rem"
  },
  buttonReset: {
    textTransform: "none"
  },
  divider: {
    height: "100%"
  },
  first: {
    paddingLeft: "0"
  },
  filter: {
    display: "flex",
    padding: "1rem 2rem",
    maxWidth: "300px"
  },
  flex: {
    display: "flex",
    justifyContent: "space-between"
  },
  formControl: {
    width: "100%",
    maxWidth: "208px",
    marginTop: ".8rem"
  },
  formSelect: {
    height: "2.4rem",
    padding: "5px"
  },
  list: {
    display: "flex",
    justifyContent: "space-between",
    padding: "0"
  },
  listItem: {
    padding: "0",
    height: "1.5rem",
    display: "flex"
  },
  leftlist: {
    marginRight: "1rem"
  },
  radio: {
    paddingLeft: "0"
  },
  searchIcon: {
    margin: "auto 1rem",
    height: "1.3rem",
    borderRadius: "0",
    "& img": {
      background: "none"
    }
  },
  searchInput: {
    flexGrow: "1"
  }
})

function SearchBar({ width, ...props }) {
  const classes = useStyles()
  const [state, setState] = useContext(VideoContext)
  const [filterActive, setFilterActive] = useState(false)
  const [typeAll, setTypeAll] = useState(false)
  const [typeTv, setTypeTv] = useState(false)
  const [typeMovies, setTypeMovies] = useState(false)
  const [typeDocu, setTypeDocu] = useState(false)
  const handleFilter = () => {
    //TODO: create a meaningful filter til we have one in place filter active will return false
    // setFilterActive(a => !a)
    setFilterActive(a => false)
  }
  const handleSearchQuery = e => {
    setState({ type: actions.SEARCH_QUERY, payload: e.target.value })
  }
  const handleCategory = () => {
    setState({ type: actions.MINI_MODAL })
  }
  return (
    <React.Fragment>
      {state.searchToggle && (
        <Paper className={classes.root}>
          <Avatar
            className={clsx(classes.searchIcon, "search")}
            alt='Search Icon'
            src={SearchIcon}
            onClick={handleFilter}
          />
          <InputBase
            className={classes.searchInput}
            placeholder='Search for Movies or TV Series'
            inputProps={{ "aria-label": "Search for Movies or TV Series" }}
            onChange={handleSearchQuery}
          />
        </Paper>
      )}
      {filterActive && (
        <Paper>
          <div className={classes.filter}>
            <Container className={clsx(classes.borderRight, classes.first)}>
              TYPE:
              <Container className={clsx(classes.list, "typelist")}>
                <div className={classes.leftlist}>
                  <div className={classes.listItem}>
                    <Radio
                      checked={typeAll}
                      onClick={() => setTypeAll(e => !e)}
                      value={typeAll}
                      icon={
                        <RadioButtonUncheckedIcon
                          fontSize='small'
                          className={classes.radio}
                        />
                      }
                      checkedIcon={
                        <RadioButtonCheckedIcon
                          fontSize='small'
                          className={classes.radio}
                        />
                      }
                      disableRipple={true}
                    />
                    <ListSubheader>All</ListSubheader>
                  </div>
                  <div className={classes.listItem}>
                    <Radio
                      checked={typeMovies}
                      onClick={() => setTypeMovies(e => !e)}
                      value={typeMovies}
                      icon={<RadioButtonUncheckedIcon fontSize='small' />}
                      checkedIcon={<RadioButtonCheckedIcon fontSize='small' />}
                      disableRipple={true}
                      className={classes.radio}
                    />
                    <ListSubheader>Movies</ListSubheader>
                  </div>
                </div>
                <div>
                  <div className={classes.listItem}>
                    <Radio
                      checked={typeTv}
                      onClick={() => setTypeTv(e => !e)}
                      value={typeTv}
                      icon={<RadioButtonUncheckedIcon fontSize='small' />}
                      checkedIcon={<RadioButtonCheckedIcon fontSize='small' />}
                      disableRipple={true}
                      className={classes.radio}
                    />
                    <ListSubheader>Tv Series</ListSubheader>
                  </div>
                  <div className={classes.listItem}>
                    <Radio
                      checked={typeDocu}
                      onClick={() => setTypeDocu(e => !e)}
                      value={typeDocu}
                      icon={<RadioButtonUncheckedIcon fontSize='small' />}
                      checkedIcon={<RadioButtonCheckedIcon fontSize='small' />}
                      disableRipple={true}
                      className={classes.radio}
                    />
                    <ListSubheader>Documentary</ListSubheader>
                  </div>
                </div>
              </Container>
            </Container>
          </div>
          <Container className={classes.buttonContainer}>
            <Button className={classes.buttonLeft} variant='outlined'>
              Filter Search
            </Button>
            <Button className={classes.buttonReset} variant='outlined'>
              Reset
            </Button>
          </Container>
        </Paper>
      )}
      {width === "xs" && (
        <div
          style={{
            position: "absolute",
            right: "0",
            top: "61px",
            marginTop: !state.searchToggle ? "-2px" : "5px",
            marginRight: "10px",
            zIndex: 100
          }}>
          <Chip
            size='small'
            icon={<ExpandMore />}
            label={
              state.categories.length > 0
                ? state.categories[state.active].name
                : ""
            }
            onClick={handleCategory}
          />
        </div>
      )}
    </React.Fragment>
  )
}

export default withWidth()(SearchBar)
