const validate = values => {
  const emailRegex = /\S+@\S+\.\S+/i

  const errors = {}
  if (!values.firstName) {
    errors.firstName = "A First Name is required."
  }
  if (!values.lastName) {
    errors.lastName = "A Last Name is required."
  }
  if (!values.email) {
    errors.email = "An Email address is required."
  }
  if (!values.password) {
    errors.password = "Please enter a password."
  }
  if (!values.confirmPassword) {
    errors.password = "Please confirm your password."
  }
  if (values.confirmPassword !== values.password) {
    errors.confirmPassword = "Be sure your passwords match."
  }
  if (values.email && !values.email.match(emailRegex)) {
    errors.email = "Please enter a valid email address."
  }
  return errors
}

export default validate
